import React from 'react';
import moment from 'moment';
import './post.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { user } from '../../utilities/utilities'
import { Gif } from "@giphy/react-components";

// using React component a we need to receive data from parent
class Post extends React.Component {
    cardWidth = 25;
    backgroundColor = "#e3f2fd";
    url = "https://the-better-media-worker.soham10.workers.dev/posts/"
    upvoteColor = "#07a1e8"
    downvoteColor = "orange"
    defaultColor = "grey"

    constructor(props) {
        super(props)
        this.upvote = React.createRef()
        this.downvote = React.createRef()
        this.post = props.post
        this.time = moment(this.post.time)
    }

    handleVotes = e => {
        console.log(e.target.className)
        let classes = e.target.className.baseVal.toString().split(' ')
        let type = classes[classes.length - 1]
        let vote = "up" === type ? 1 : -1;

        // get refernce to the path element inside the svg node
        let upvote = this.upvote.current.childNodes[0].childNodes[0]
        let downvote = this.downvote.current.childNodes[0].childNodes[0]

        // color according to user selection
        if (vote === 1) {
            upvote.style.fill = this.upvoteColor
            downvote.style.fill = this.defaultColor
        }
        else {
            upvote.style.fill = this.defaultColor
            downvote.style.fill = this.downvoteColor
        }

        // create votes attribute if doesn't exist
        if (this.post["votes"] === undefined) {
            this.post["votes"] = []
        }

        // find if update is needed or a new voter to be added
        let index = this.post.votes.findIndex(v => v.user === user)

        // if index -1, then user has not voted
        if (index === -1) {
            // add vote accordingly
            this.post.votes.push({ user: user, vote: vote })
            console.log("added new vote by user " + user + " to " + vote)
        }
        else {
            // update the vote accordinly
            this.post.votes[index].vote = vote
            console.log("updated vote by user " + user + " to " + vote)
        }

        // make a put call to update the element in the db
        // as we use a key value store, we can just make a post call
        fetch(this.url, {
            method: 'POST',
            mode:'no-cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.post),
        }).then(e => {
            console.log("post call successful")
        })

        // set state to re-render the component
        this.setState(this.post);
    }

    getVotes = (type = 1) => {
        if (this.post.votes === undefined) {
            return 0
        }
        else {
            let count = 0;
            this.post.votes.forEach(v => {
                if (v.vote === type) {
                    count += 1;
                }
            })
            return count
        }
    }

    checkVote = () => {
        // find if update is needed or a new voter to be added
        if (this.post.votes === undefined) {
            return;
        }
        let index = this.post.votes.findIndex(v => v.user === user)
        // get refernce to the path element inside the svg node
        let upvote = this.upvote.current.childNodes[0].childNodes[0]
        let downvote = this.downvote.current.childNodes[0].childNodes[0]

        // if index -1, then user has not voted
        if (index !== -1) {
            // update vote accordingly
            let vote = this.post.votes[index].vote
            if (vote === 1) {
                upvote.style.fill = this.upvoteColor
            }
            else {
                downvote.style.fill = this.downvoteColor
            }
        }
    }

    showGIF = () => {
        return (
            <div className="d-flex justify-content-center">
                <Gif className="card-img-top" gif={this.post.gif} alt="a gif posted by the user" width={200} />
            </div>
        )
    }


    // update the button color according to vote
    componentDidMount() {
        this.checkVote();
    }

    render() {
        return (
            <div className="card text-dark border-dark post" style={{ minWidth: this.cardWidth + 'rem' }} >
                <div className="card-header">
                    Posted by {this.post.user}
                </div>
                {this.post.gif === undefined ? null : this.showGIF()}
                <div className="card-body" >
                    <h5 className="card-title">{this.post.title}</h5>
                    <p className="card-text" >{this.post.content}</p>
                    <div className='card-buttons'>
                        <div className="card-button-vote" ref={this.upvote} >
                            <FontAwesomeIcon className="thumbs up" onClick={this.handleVotes} icon={faThumbsUp} ></FontAwesomeIcon>
                            <span className="vote-count">{this.getVotes(1)}</span>
                        </div>
                        <div className="card-button-vote" ref={this.downvote}>
                            <FontAwesomeIcon className="thumbs down" onClick={this.handleVotes} icon={faThumbsDown} ></FontAwesomeIcon>
                            <span className="vote-count">{this.getVotes(-1)}</span>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <small className="text-muted">Last updated {this.time.fromNow()}</small>
                </div>

            </div>
        );
    }
}
export default Post;