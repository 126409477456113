import React, { useEffect, useState } from "react";
import Post from '../post/post'
import CreatePost from '../create-post/createPost'
import './posts.css';
import moment from "moment";

const Posts = () => {
    const [posts, setPosts] = useState([]);

    const getPosts = async () => {
        const resp = await fetch(
            "https://the-better-media-worker.soham10.workers.dev/posts/"
        );
        try {
            const postsResp = await resp.json();
            let posts = postsResp.map(p => JSON.parse(p))
            posts.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return moment(b.time) - moment(a.time);
            });
            setPosts(posts);
        }
        catch (error) {
            console.log(error);
            console.log(error.stack);
        }
    };

    useEffect(() => {
        getPosts();
    }, []);

    return (
        <div>
            <div className='d-flex justify-content-center create-post'>
                <CreatePost
                    // update the feed after 1 sec of the post call
                    onCreated={() => {
                        setTimeout(() => getPosts(), 1000)
                    }}
                />
            </div>
            <div className="feed">
                <h4>Feed</h4>
                <h5>Swipe away</h5>
            </div>
            <div className="parent">
                <div className="post-groups">
                    {posts.map((post) => (
                        <Post className="item" key={post.id} post={post} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Posts;