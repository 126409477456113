import React, { Component } from 'react';
import './createPost.css'
import moment from 'moment';
import { uuidv4, user } from '../../utilities/utilities';
import ReactGiphySearchbox from 'react-giphy-searchbox'

import { Gif } from "@giphy/react-components";

class CreatePost extends Component {
    state = { showForm: false, showMedia: false }
    formWidth = 250
    url = "https://the-better-media-worker.soham10.workers.dev/posts/"

    post = {
        user: user,
        title: '',
        content: '',
        time: moment(),
        id: ''
    };

    handleChange = event => {
        this.post[event.target.id] = event.target.value
    }

    onSubmit = e => {
        this.post.time = moment().format()
        this.post.id = uuidv4()
        console.log(JSON.stringify(this.post))
        fetch(this.url, {
            method: 'POST',
            mode:'no-cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.post),
        })
            .then(() => {
                this.setState({ showForm: false })
                this.props.onCreated()
            });
    }

    showButton = () => {
        return <button className="btn btn-primary" onClick={() => this.setState({ showForm: true })}>Create a new post</button>
    }

    showGiphyPicker = () => {
        return (
            <div className="input-group mb-3 giphy-search" >
                <ReactGiphySearchbox
                    apiKey={process.env.REACT_APP_GIPHY_KEY}
                    onSelect={this.log.bind(this)}
                />
            </div>
        )
    }

    showGiphyButton = () => {
        if (this.post.gif === undefined) {
            return (
                <div className="input-group mb-3" >
                    <button className="btn btn-light add-gif" onClick={() => this.setState({ showMedia: true })}>Add a GIF</button>
                </div>
            )
        }
        else {
            return (
                <div className="input-group mb-3" >
                    <Gif gif={this.post.gif} width={200} height={200} />
                    <button className="btn btn-light update-gif" onClick={() => this.setState({ showMedia: true })}>Update the GIF</button>
                </div>
            )
        }
    }

    log(gif) {
        console.log(gif);
        this.setState({ showMedia: false });
        this.post.gif = gif;
    }

    showForm = () => {
        return (
            <div style={{ minWidth: this.formWidth + "px" }}>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">@</span>
                    <input type="text" className="form-control username" value={this.post.user} aria-label="Username" aria-describedby="basic-addon1" disabled />
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="title-addon">What's it about?</span>
                    <input type="text" className="form-control title" onChange={this.handleChange} aria-describedby="title-addon" id="title" />
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="content-addon">Tell us more!!</span>
                    <textarea type="text" className="form-control content" onChange={this.handleChange} aria-describedby="content-addon" id="content" />
                </div>
                {this.state.showMedia ? this.showGiphyPicker() : this.showGiphyButton()}
                <div className='d-flex justify-content-between'>
                    <button className="btn btn-primary publish" onClick={this.onSubmit} >Publish</button>
                    <button className="btn btn-primary publish" onClick={() => this.setState({ showForm: false })} >Back</button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className='create-post'>
                {this.state.showForm ? null : this.showButton()}
                {this.state.showForm ? this.showForm() : null}
            </div>
        );
    }

}
export default CreatePost