import { Router } from "@reach/router";

import Posts from "../components/posts/posts";
import { user } from "../utilities/utilities"

function Dashboard() {
  return (
    <div>
      <nav className="navbar navbar-dark bg-dark">
        <div className="container-fluid">
          <span className="navbar-brand mb-0 h1">The Better Media</span>
          <span className="navbar-brand mb-0 h4">Logged in as {user}</span>
        </div>
      </nav>
      <Router>
        <Posts path="/" />
      </Router>
    </div>
  );
}

export default Dashboard;
